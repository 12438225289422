import React from "react"
import { Link } from "gatsby"
import AwesomeSlider from "../components/react-awesome-slider/src/index.js"
import "../components/react-awesome-slider/dist/styles.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import slide1 from "../images/slide-1.jpg"
import slide2 from "../images/slide-2.jpg"
import Reasons from "../components/reasons"
import FormDivider from "../components/form-divider"
import Testimonials from "../components/testimonials"
import Fade from "react-reveal/Fade"
import ReactMarkdown from "react-markdown"

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { modal: false }
  }
  handleModal = () => {
    this.setState({ modal: !this.state.modal })
  }
  render() {
    const { modal } = this.state
    const {
      data: { markdownRemark },
    } = this.props
    return (
      <Layout>
        <SEO title="Home" />

        <AwesomeSlider>
          {markdownRemark.frontmatter.slider.slide.map(slide => {
            return (
              <div data-src={slide.image}>
                <div className="hero">
                  <div className="caption-container">
                    <Fade top>
                      <ReactMarkdown source={slide.content} />
                    </Fade>
                    <Link to={slide.link.url}>{slide.link.text}</Link>
                  </div>
                </div>
              </div>
            )
          })}
        </AwesomeSlider>

        <main className="main-container">
          <div className="title-container">
            <h1 className="page-title">{markdownRemark.frontmatter.title}</h1>
          </div>
          <div className="main-content-container">
            <div
              dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
            ></div>
          </div>
          <Reasons bottom />
        </main>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Home" } }) {
      frontmatter {
        title
        templateKey
        formContent {
          title
          content
        }
        slider {
          slide {
            content
            image
            link {
              text
              url
            }
          }
        }
      }
      html
    }
  }
`

export default IndexPage
